.bg-img {
    /* The image used */
    background-position: top; 
    background-image: url("seniors.jpeg");



  
    min-height: 720px;
  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
    /* Needed to position the navbar */
    position: relative;
  }
  
  /* Position the navbar container inside the image */
  .container {
    position: absolute;
    margin: 2px;
    width: 100%;
  }
  
  /* The navbar */
  .topnav {
    overflow: hidden;
    background-color: #333;
  }
  
  /* Navbar links */
  .topnav a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }