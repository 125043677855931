body {
    margin: 0;
    box-sizing: border-box;
}

.app {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}
